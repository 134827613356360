import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom"

import ContactForm from './ContactForm';
import { Link } from 'react-router-dom';

function Bannerofproductspage() {
  const { id } = useParams(); // <-- access id match param here

  const [data, setData] = useState([]); // Initialize data as an empty array
  const [categoryDetails, setCategoryDetails] = useState([]); // Initialize contactDetails as an empty array

  useEffect(() => {
    // Define the API URLs for fetching data
    const quickLinksUrl = '/get_home_banner';
    const logurl = `/get_logo`;

    // Fetch quick links data using Axios
    axios.get(quickLinksUrl)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching quick links data:', error);
      });

    // Fetch company details data using Axios
    axios.get(logurl)
      .then(response => {
        setCategoryDetails(response.data.data); // Corrected line
      })
      .catch(error => {
        console.error('Error fetching company details data:', error);
      });

  }, []);
  return (

    <Container fluid >
      <Row className=" banner topPadding">
        {data.map((item) => (
          <>
            <Col lg={3} className="productbannerleft"
              key={item.id}
              style={{
                backgroundColor: item.background_color_left,
              }}
            >

            </Col>
            <Col lg={9} className="productbannerright"
              style={{
                backgroundColor: item.background_color_right,
              }}>
              <Col >
                {" "}
                {categoryDetails.map((item) => (
                  <Link to={`/`}>
                    <img src={item.image} fluid alt="" className="logo" />
                  </Link>
                ))}
              </Col>
              <Col>
                {" "}

                {data.map((item) => (
                  <Col>
                    <div key={item.id}>
                      <img
                        src={item.image}
                        alt=""
                        className="bannerimg shadow-lg img-fluid"
                      />

                    </div>
                  </Col>
                ))}
                <Col>
                  <div className="bannerconatctform text-white  contact-top-50 end-0 translate-middle-contact d-lg-block d-none">
                    <ContactForm />
                  </div>
                </Col>
              </Col>
            </Col>
          </>
        ))}

      </Row>
    </Container>

  );
}

export default Bannerofproductspage;
