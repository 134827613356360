import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";

const Moreaboutus = () => {
  const [data, setData] = useState([]); // Initialize data as an empty array
  const [truncated, setTruncated] = useState(true);
  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = "/get_aboutus"; // Replace with your API URL

    // Make the API request using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <Container fluid className="mediaforsaleimg pt-2 aboutmarginbottom">
        {data.length > 0 && (
          data.map((item) => (
            <Row key={item.id} className="sale-main mt-5">
              <Col
                lg={5}
                md={12}
                sm={12}
                className="d-flex align-items-center px-lg-5 item"
              >
                <div className="saleAdvt text-md-start d-grid font-21 align-items-center font-family">
                  <h6 className="fs-5">{item.title}</h6>
                  <h1 className="font-family font-96 font-21 font-weight-medium font-family">
                    {item.short_description}
                  </h1>
                  <p
                    style={{ textAlign: "justify" }}
                    className="font-family font-15 font-weight-light font-family"
                  >
                    {item.long_description}
                  </p>
                </div>
              </Col>
              <Col
                lg={7}
                md={12}
                sm={6}
                className="d-flex justify-content-center main-sale item"
              >
                <Col className="saleleft"></Col>
                <div className="saleround"></div>
                <img className="float-lg-right saleim" src={item.image} alt="" />
                <Col className="saleright"></Col>
              </Col>
            </Row>
          ))
        )}
      </Container>
    </>
  );
};

export default Moreaboutus;
