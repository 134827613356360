import React, { useState, useEffect } from "react";
import axios from "axios";

const Counter = () => {
  const [data, setData] = useState(null); // Initialize data as null

  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = "/get_counts"; // Replace with your API URL

    // Make the API request using Axios
    axios
      .get(apiUrl)
      .then((response) => {
        const responseData = response.data;

        // Check if the response contains the expected data structure
        if (responseData && responseData.status === "Success") {
          setData(responseData);
        } else {
          console.error("Invalid API response:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      {data ? (
        <div className="d-flex justify-content-evenly p-2 counter">
          <div className="">
            <span className="font-21 font-46 font-weight-medium font-family d-flex justify-content-center">
              {data.total_brands}+
            </span>{" "}
            <p className="font-18 font-weight-medium font-family"> BRANDS</p>
          </div>
          <div className="">
            <span className="font-21 font-46 font-weight-medium font-family d-flex justify-content-center">
              {data.Total_products}+
            </span>{" "}
            <p className="font-18 font-weight-medium font-family"> PRODUCTS</p>
          </div>
          <div className="">
            <span className="font-21 font-46 font-weight-medium font-family d-flex justify-content-center">
              {data.Total_shops}+
            </span>{" "}
            <p className="font-18 font-weight-medium font-family">SHOPS</p>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Counter;
