import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "./Header";
import BannerCategory from "./BannerCategory";
import { MySecondContext } from "../context/CategoryBanner";

const Productcategoriesrounds = () => {
 
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState(null);
  
  const { setBannerCategoryID } = useContext(MySecondContext);
  
  const asd = localStorage.setItem("category_Id", id);
 
  const fetchData = async () => {
    try {
      // Make the first API request to get the main category title
      const mainCategoryResponse = await axios.get(`/get_maincategory`);
      const title = mainCategoryResponse.data.data.find((city) => city.id == id);
      setCategoryName(title ? title.title : "");
  
      // Make the second API request to get subcategory data
      const subcategoryResponse = await axios.get(`/get_subcategory/${id}`);
      setData(subcategoryResponse.data.data);
    } catch (error) {
      setError("Error fetching data: " + error.message);
    }   
  };
  
  useEffect(() => {
    fetchData();
  }, [id]);
  
  return (
    <>
   
      <BannerCategory />
      <Header />
      <Container fluid className=" d-grid justify-content-center">
        <Row className="py-lg-5"> 
          <div className="font-27 font-32 font-weight-medium font-family pt-3 d-flex justify-content-center">
            {" "}
            CATEGORIES ({categoryName}){" "}
          </div>

          {data.map((item) => (
            <>
              <Col className="col-sm-3 col-md-3 col-lg-2 col-4 d-grid justify-content-center" key={item.id}>
                <Link
                  to={`/productlist/${item.id}`}
                  // onClick={() => setBannerCategoryID(id)}
                  
                  // to={{ pathname: `/productlist`, state: { userId: 123 } }}
                  className="underline-remove arrow-color"
                >
                  <div className="catrogieimg mt-5 align-items-center  d-flex justify-content-center">
                    <img
                      src={item.image}
                      className="w-75 h-75 d-flex   justify-content-center cursor-pointer"
                      alt=""
                    />
                  </div>
                </Link>
                {/* </Link> */}
                <div className=" text-center text-uppercase fs-lg-5 font-17 font-22 pt-3 font-weight-medium font-family py-2">
                  {item.title}
                </div>
              </Col>
            </>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Productcategoriesrounds;
