import React, { useState } from "react";
import axios from "axios"; // Import Axios
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const initialFormData = {
  full_name: "",
  email: "",
  mobile_no: "",
  gender: "male",
  message: "",
};

function ContactForm() {
  const [formData, setFormData] = useState(initialFormData);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true); // Show validation errors if the form is invalid
        return;
      }
      // Make a POST request to your API endpoint
      const response = await axios.post("/add_contactform", formData);

      // Handle success
      console.log("Thank You, We Will Connect You Soon ", response.data);
      setSubmissionStatus("success");

      // Clear the form after successful submission
      setFormData(initialFormData);
      setValidated(false); // Reset validation state

      setTimeout(() => {
        setSubmissionStatus(null);
      }, 2000);
    } catch (error) {
      // Handle error
      console.error("Error sending data:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <div className="contact-main">
      <label className="paddleftcontact pt-2 font-32 font-weight-bold font-family">
        CONTACT
      </label>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-grid align-content-center justify-content-center"
      >
        {/* Display a success or error message */}
        {submissionStatus === "success" && (
          <div className="alert alert-success">Thank You, We Will Connect You Soon !</div>
        )}
        {submissionStatus === "error" && (
          <div className="alert alert-danger">
            Error sending data. Please try again later.
          </div>
        )}

        <div className="">
          <div className="col-lg-12 col-md-12 col-sm-12"></div>

          <div className="px-2">
            <Form.Group >
              <Form.Label>Full Name</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleInputChange}
                  className="input-field form-control"
                  required
                  aria-describedby="inputGroupPrepend"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Full Name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>

          <div className="px-2">
            <Form.Group >
              <Form.Label className="pt-3">Email Address</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="input-field form-control mt-0"
                  required
                  aria-describedby="inputGroupPrepend"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Email Address
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>

          <div className="px-2">
            <Form.Group >
              <Form.Label className="pt-3">Mobile Number</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="tel"
                  id="mobile_no"
                  name="mobile_no"
                  pattern="[789]{1}[0-9]{9}"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                  }}
                  maxLength="10" // Change minlength to maxLength
                  minLength="10"
                  value={formData.mobile_no}
                  onChange={handleInputChange}
                  className="input-field form-control"
                  required
                  aria-describedby="inputGroupPrepend"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Mobile Number
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>
          <div className="px-2">
            <label htmlFor="gender" className="pt-3">
              Gender
            </label>

            <div className="d-flex">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="male"
                  value="male"
                  checked={formData.gender === "male"}
                  onChange={handleInputChange}
                />
                <label className="form-check-label mr-1" htmlFor="male">
                  Male&nbsp;
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="female"
                  value="female"
                  checked={formData.gender === "female"}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="female">
                  Female&nbsp;
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="other"
                  value="other"
                  checked={formData.gender === "other"}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="other">
                  Other
                </label>
              </div>
            </div>
          </div>

          <div className="px-2">
            <Form.Group >
              <InputGroup hasValidation>
                <Form.Control
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  as="textarea"
                  rows={3}
                  placeholder="Message"
                  className="input-fieldm mt-2 form-control"
                  required
                  aria-describedby="inputGroupPrepend"
                />
                <Form.Control.Feedback type="invalid">
                  Please Enter Message
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </div>

          <div className="px-2">
            <button
              type="submit"
              className="btn-send w-50 px-3 py-2 my-3 text-white"
            >
              Send
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default ContactForm;
