import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const Popularcategories = () => {
  const [popular, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = "/get_maincategory"; // Replace with your API URL
        const response = await axios.get(apiUrl);
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const categoriesPerRow = 4; // Number of categories per row
  const categoryRows = Math.ceil(popular.length / categoriesPerRow);

  return (
    <Container fluid className="popular-mains">
      <div className="ps-lg-5 font-27 font-32 font-weight-medium font-family pt-lg-2 b-lg-2 tittlepop">
        Categories
      </div>
      {loading ? (
        <div className="col-12 text-center mt-4">
          <h5>Loading...</h5>
        </div>
      ) : popular.length === 0 ? (
        <div className="col-12 text-center mt-4">
          <h5>No Data Found For Categories.</h5>
        </div>
      ) : (
        <div className="row popularcat">
          {popular.map((item) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-6 d-flex adjs justify-content-center" key={item.id}>
              <div className="backofround">
                <div className="roundpopular"></div>
                <img src={item.image} className="img-fluid imgpp" alt="" />
                <Link to={`/categorydetails/${item.id}`}>
                  <button className="font-17 font-24 font-weight-medium font-family popbtn">
                    {item.title}
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

export default Popularcategories;
