import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import "../assets/scss/detailpage/productdetailpage.css";
import logo from "../assets/images/1687931517124H 7.png";
import Modal from "react-bootstrap/Modal"; // Add this import
import { useParams } from "react-router-dom";
import ImageMagnifier from "./ImageMagnifier";
import { Link } from "react-router-dom";
import Header from "./Header";
import Slider from "react-slick";
import { MyContext } from "../context/ProductsContext";
function ProductDitailPage({ }) {
  // const [zoomed, setZoomed] = useState(false);
  // const [zoomedImageSrc, setZoomedImageSrc] = useState(""); // Store the zoomed image source

  // const toggleZoom = (imageSrc) => {
  //   setZoomed(!zoomed);
  //   setZoomedImageSrc(imageSrc);
  // };

  const [showModal1, setShowModal1] = useState(false);
  const [zoomedImageSrc1, setZoomedImageSrc1] = useState("");

  const openModal = (imageSrc) => {
    setZoomedImageSrc1(imageSrc);
    setShowModal1(true);
  };

  const closeModal1 = () => {
    setShowModal1(false);
  };

  const { productCategoryID } = useContext(MyContext);

  console.log("productCategoryID", productCategoryID);

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Define the API URL you want to fetch data from
        const apiUrl = `/get_products_details/${id}`;

        // Make the API request using Axios to fetch product details
        const response = await axios.get(apiUrl);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      // Fetch company details data using Axios
      try {
        const apiProductUrl = `/get_products/${localStorage.getItem('product_Id')}`;
        axios.get(apiProductUrl).then((response) => {
          setSimilarProducts(response.data.data);
          console.log(response.data.data, "data");
        });
      } catch (error) {
        console.error("Error fetching company details data:", error);
      }

      // Fetch company details data using Axios
      try {
        const apiProductUrl = `/get_logo`;
        axios.get(apiProductUrl).then((response) => {
          setLogo(response.data.data);
          console.log(response.data.data, "data");
        });
      } catch (error) {
        console.error("Error fetching company details data:", error);
      }
    };

    fetchData();
  }, [id, productCategoryID]); // Make sure to include id as a dependency to trigger the effect when it changes
  let bestslides;
  similarProducts.length < 4
    ? (bestslides = similarProducts.length)
    : (bestslides = 4);

  const settings = {
    infinite: false,
    speed: 5000,
    slidesToShow: bestslides,
    slidesToScroll: bestslides,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }; // Initialize data as an empty array

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 3000, // Change slide every 3 seconds
  };
  return (
    <>
      <Container  fluid className="banner-main">
        <div >
          <div className="productdetailpage-main shadow-lg">
            
            {data.map((item) => {
              return (
                <>
                  <Row className="  banner" key={item.id}>
                    <Col lg={4} className="productbannerleft1  ">
                      <div className="smallround"></div>
                    </Col>
                    <Col lg={8} md={12} className="productbannerright1">
                      <Col>
                        {" "}
                        {logo.map((item) => (
                          <Link to={`/`}>
                            <img
                              src={item.image}
                              fluid
                              alt=""
                              className="logo"
                            />
                          </Link>
                        ))}
                      </Col>
                      <div className="prdnav d-block d-lg-none">
                            < Header />
                          </div>
                      <Col lg={12} className="contents pt-5 shadow-lg">
                        <Row className=" p-3 mb-5 bg-body mx-0 contentsds  rounded">
                          <Col
                            lg={5}
                            className=" d-flex justify-content-center"
                          >
                            <ImageMagnifier
                              src={item.thumbnail_image}
                              className="img-fluid detailbigimageB"
                              magnifierWidth={200}
                              magnifierHeight={200}
                              zoomLevel={2}
                            />
                            {/* <img
                              src={item.thumbnail_image}
                              fluid
                              className="img-fluid detailbigimageB"
                              alt=""
                            /> */}
                          </Col>
                          
                          <Col lg={7} md={12}>
                            <div className=" my-4">
                              {" "}
                              <div className="font-19 fw-bold jockey ">
                                {item.brands}
                              </div>
                              <div className="font-46 font-weight-bold font-family greenshirt">
                                {item.name}
                              </div>
                              <div>
                                <p className="font-13 font-weight-medium font-family">
                                  {item.description}
                                </p>
                              </div>
                              <div>
                                {" "}
                                {/* <button className="contactusbtn font-20 fw-bold p-2">
                                  Contact Us
                                </button> */}
                              </div>
                            </div>

                            {/* <div className="d-flex">
                              {item.product_images.map((image, index) => (
                                <div key={index}>
                                </div>
                              ))}
                            </div> */}

                            <Row className="shadow detailsproductimg">
                              <Slider {...settings1}>
                                {item.product_images.map((image, index) => (
                                  <div key={index}>
                                    <img
                                      src={image.img}
                                      className="img-fluid multi-image detailbigimage shadow-lg mx-3 zoom"
                                      alt={`Image ${index + 1}`}
                                      //  magnifierWidth={400}
                                      //  magnifierHeight={400}
                                      //  zoomLevel={5}
                                      onClick={() => openModal(image.img)}
                                    // onClick={() => toggleZoom(image.img)} // Toggle zoom on click
                                    // src={image} alt={`Image ${index}`}
                                    />
                                  </div>
                                ))}
                              </Slider>
                            </Row>
                          </Col>
                        </Row>

                        <Col></Col>
                      </Col>
                    </Col>
                  </Row>
                  <div className=" d-none d-lg-block">
                    < Header />
                  </div>
                  <Container fluid className="py-5 sivrediv">
                    <div>


                      <Row className=" sililarproductslider">
                        <h1 className=" font-27 font-32 font-weight-medium font-family pt-3 d-flex justify-content-center">
                          Similar
                        </h1>
                        <Slider {...settings}>

                          {Array.isArray(similarProducts) &&
                            similarProducts.map((item) => (
                              <>
                                <div className="rested" key={item.id}>
                                  {" "}
                                  <div className="col-sm-6 col-6 col-lg-3 col-md-6">
                                    <div className="profile-card ">
                                      <div className="profile-img">
                                        <img
                                          src={item.thumbnail_image}
                                          alt="Team Image"
                                          className="img-fluid "
                                        />
                                      </div>
                                      <div className="profile-content">
                                        <h2 className="title font-22 font-weight-bold font-family">
                                          {item.name}
                                          <div>
                                            <span className="font-20 font-17 font-weight-light font-family">
                                              {item.brands}
                                            </span>
                                          </div>
                                        </h2>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}

                          <Modal
                            show={showModal1}
                            onHide={closeModal1}
                            centered
                            size="md"
                            className="modalDisplay"
                          >
                            <div className="d-flex justify-content-end p-2">
                              <button onClick={closeModal1} type="button" className="btn-close d-flex" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <Modal.Body>
                              <img
                                src={zoomedImageSrc1}
                                className="img-fluid"
                                style={{ height: '500px' }}
                                alt="Zoomed Image"
                              // magnifierWidth={200}
                              // magnifierHeight={200}
                              // zoomLevel={2}
                              />
                            </Modal.Body>

                          </Modal>
                        </Slider>
                      </Row>


                    </div>
                  </Container>
                </>
              );
            })}
          </div>
        </div>
      </Container>
    </>
  );
}

export default ProductDitailPage;
