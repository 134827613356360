import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";

const Sale = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = "/get_aboutus"; // Replace with your API URL
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false, whether the request succeeds or fails
      }
    };

    fetchData();
  }, []);

  return (
    <Container fluid className="mediaforsaleimg mainaboutus pt-2 pb-5">
      {loading ? (
        <div className="col-12 text-center mt-4">
          <h5>Loading...</h5>
        </div>
      ) : data.length > 0 ? (
        data.map((item) => (
          <Row key={item.id} className="sale-main about-postion mt-5">
            <Col
              lg={5}
              md={12}
              sm={12}
              className="d-flex align-items-center px-lg-5 px-md-3 px-sm-3 item"
            >
              <div className="saleAdvt text-md-start d-grid align-items-center">
                <p className="fs-5 font-family font-21 font-46 font-weight-medium">
                  {item.title}
                </p>
                <h1 className="font-family font-21 font-46 font-weight-medium">
                  {item.short_description}
                </h1>
                <p
                  style={{ textAlign: "justify" }}
                  className="about-text font-family font-15 font-weight-light"
                >
                  {item.long_description}
                </p>
                <Link to={"../About"}>
                  <Button className="contactbtn rounded-0 mb-4 border-0">
                    <span className="font-family font-17 font-20 font-weight-medium">
                      Read More
                    </span>
                  </Button>
                </Link>
              </div>
            </Col>
            <Col
              lg={7}
              md={12}
              sm={6}
              className="d-flex justify-content-center main-sale item"
            >
              <Col className="saleleft"></Col>
              {/* <div className="saleround"></div> */}
              <img
                className="float-lg-right saleim"
                src={item.image}
                alt=""
              />
              <Col className="saleright"></Col>
            </Col>
          </Row>
        ))
      ) : (
        <div className="col-12 text-center mt-4">
          <h5>No Data Found for About Us.</h5>
        </div>
      )}
    </Container>
  );
};

export default Sale;
