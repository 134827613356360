import React, { useState } from "react";
import axios from "axios"; // Import Axios
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Container } from "react-bootstrap";

const initialFormData = {
  full_name: "",
  email: "",
  mobile_no: "",
  gender: "",
  message: "",
};

function ContactInformation() {
  const [formData, setFormData] = useState(initialFormData);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();
        setValidated(true); // Show validation errors if the form is invalid
        return;
      }

      // Make a POST request to your API endpoint
      const response = await axios.post("/add_contactform", formData);

      // Handle success
      console.log("Thank You, We Will Connect You Soon :", response.data);
      setSubmissionStatus("success");

      // Clear the form after successful submission
      setFormData(initialFormData);
      setValidated(false); // Reset validation state

      setTimeout(() => {
        setSubmissionStatus(null);
      }, 2000);
    } catch (error) {
      // Handle error
      console.error("Error sending data:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <Container className="d-lg-none d-sm-block">
      <div className="contact-card-shadow ">
        <label className="px-2 py-4 font-32 font-weight-medium font-family">
          CONTACT
        </label>
        <div className="row shadow-lg p-3 mb-5 bg-white rounded">
          <div className="col-lg-4 col-md-4 col-sm-4 ">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="align-content-center justify-content-center"
            >
              {/* Display a success or error message */}
              {submissionStatus === "success" && (
                <div className="alert alert-success">
                  Thank You, We Will Connect You Soon!
                </div>
              )}
              {submissionStatus === "error" && (
                <div className="alert alert-danger">
                  Error sending data. Please try again later.
                </div>
              )}
              <div className="px-2">
                <Form.Group controlId=" validationCustomUsername">
                  <Form.Label>Full Name</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleInputChange}
                      className="input-field form-control"
                      required
                      aria-describedby="inputGroupPrepend"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please entre Full Name
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="px-2">
                <Form.Group controlId=" validationCustomUsername">
                  <Form.Label className="pt-3">Email Address</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="input-field form-control mt-0"
                      required
                      aria-describedby="inputGroupPrepend"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please entre Email Address
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="px-2">
                <Form.Group controlId=" validationCustomUsername">
                  <Form.Label className="pt-3">Mobile Number</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="tel"
                      id="mobile_no"
                      name="mobile_no"
                      pattern="[789]{1}[0-9]{9}"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                      }}
                      maxlength="10"
                      minlength="10"
                      value={formData.mobile_no}
                      onChange={handleInputChange}
                      className="input-field form-control"
                      required
                      aria-describedby="inputGroupPrepend"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please entre Mobile Number
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="px-2 labelcontactform">
                <label htmlFor="gender" className="pt-3">
                  Gender
                </label>

                <div className="d-flex">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="male"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label mr-1" htmlFor="male" style={{color:'black !important'}}>
                      Male&nbsp;
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="female"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="female">
                      Female&nbsp;
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="other"
                      value="other"
                      checked={formData.gender === "other"}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label" htmlFor="other">
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <div className="px-2">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <InputGroup hasValidation>
                    <Form.Control
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      className="input-fieldm mt-2 form-control"
                      required
                      aria-describedby="inputGroupPrepend"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please entre Message
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="px-2">
                <button
                  type="submit"
                  className="btn-send w-25 px-3 py-2 my-3 text-white"
                >
                  Send
                </button>
              </div>
            </Form>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3749.152480717311!2d73.76866021488044!3d20.00211408656353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb5d071f476b%3A0x2fc42b315b22b51d!2sShree%20Mahalasa%20%26%20Company!5e0!3m2!1sen!2sin!4v1609319508167!5m2!1sen!2sin"
              width="100%"
              height="600"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ContactInformation;
