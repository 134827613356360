import React, { useState, useEffect } from 'react';
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import axios from 'axios';
import { Link } from "react-router-dom";

const BrandPageList = () => {
  const [logobrandarray, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8); // Use a constant for items per page
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = '/get_brands';

    axios.get(apiUrl)
      .then(response => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Make sure to set loading to false on error
      });
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = logobrandarray.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container fluid className="">
      <Row className="brandcontainer brandsback">
        <div className="font-27 font-32 font-weight-medium font-family ps-lg-5">Brands</div>
        <div className='row d-flex justify-content-center '>
          {loading ? (
            <div className="col-12 text-center mt-4">
              <h5>Loading...</h5>
            </div>
          ) : currentItems.length > 0 ? (
            currentItems.map((item) => (
              <div className="text-center brand-card-padding col-sm-6 col-lg-3 col-md-4 my-0" key={item.id}>
                <Link
                  to={`/brandparticulardetails/${item.id}`}
                  className="underline-remove arrow-color d-flex justify-content-center"
                >
                  <div className="brandcard vert-move">
                    <img
                      src={item.image}
                      className="brand-img-size m-4 img-fluid fixed-image"
                      alt=""
                    />
                    <p className="text-center tttitlt font-17 font-26 font-weight-medium font-family brandlistellipse">{item.title}</p>
                    <p className='px-3 ellipsetext font-15 font-weight-medium font-family'>{item.description}</p>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="col-12 text-center mt-4">
              <h5>No Data Found for Brand.</h5>
            </div>
          )}
        </div>
        <div className="pagination pt-4 d-flex justify-content-center">
          {logobrandarray.length > itemsPerPage && (
            <ul className="pagination justify-content-center">
              {Array.from({ length: Math.ceil(logobrandarray.length / itemsPerPage) }).map((_, index) => (
                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button className="page-link" onClick={() => paginate(index + 1)}>
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Row>
    </Container>
  );
};

export default BrandPageList;
