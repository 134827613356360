import React, { createContext, useState } from "react";

const MyContext = createContext();

export default function ProductsContext({ children }) {
  const [productCategoryID, setProductCategoryID] = useState("");

  return (
    <MyContext.Provider value={{ productCategoryID, setProductCategoryID }}>
      {children}
    </MyContext.Provider>
  );
}

export { MyContext };
