import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import Bannerofproductspage from "./Bannerofproductspage";
import Header from "./Header";
import ImageMagnifier from "./ImageMagnifier";

const BrandParticularDetails = () => {
  const { id } = useParams();
  const [logobrandarray, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiDetailUrl = `/get_brand_details/${id}`;
        const response = await axios.get(apiDetailUrl);
        setData(response.data.data);
        setLoading(false);
        console.log(response.data.data, "details");
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
     
      <Bannerofproductspage />
      <Header />
      <Container fluid className="py-lg-5">
        <Row>
          <div className="font-27 font-32 font-weight-medium font-family ps-lg-5">
            Brands
          </div>
          <div className="row d-flex justify-content-center">
          {loading ? (
            <div className="col-12 text-center mt-4">
              <h5>Loading...</h5>
            </div>
          ) : logobrandarray.length > 0 ? (
              logobrandarray.map((item) => (
                <Row className="d-flex align-items-center justify-content-center p-4" key={item.id}>
                    <div className="col-lg-9 col-mg-9 col-sm-9 bg-light shadow p-lg-4">
                  <div className="text-center d-lg-flex" key={item.id}>
                    <div className=" col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center align-items-center">
                      <img
                        src={item.image}
                        className="vert-move img-fluid"
                        alt="" style={{width:'200px'}}
                      />
                    </div>
                    <div className="col-lg-6  col-md-6 col-sm-6 d-flex align-items-center justify-content-center">
                        <div className="d-grid align-items-center justify-content-center ">
                        <p className="vert-move text-center font-17 font-32 font-weight-semibold font-family">{item.title}</p>
                      <p className="vert-move brandsescrip px-3 font-13 font-18 font-weight-medium font-family">{item.description}</p>
                        </div>
                    </div>
                  </div>
                  </div>
                </Row>
              ))
              ) : (
                <div className="col-12 text-center mt-4">
                  <h5>No Data Found for Brand.</h5>
                </div>
              )}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BrandParticularDetails;
