import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";

const Brandsround = () => {
  const { id } = useParams();
  const [logobrandarray, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiUrl = "/get_top_brands"; // Replace with your API URL

    // Use async/await to simplify asynchronous code
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const limitedData = response.data.data;
        setData(limitedData);
        setLoading(false); // Set loading to false when data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function
  }, []);

  return (
    <Container fluid className="">
      <Row className="brandcontainerhome">
        <div className="font-27 font-32 font-weight-medium font-family ps-lg-5">
          Brands
        </div>
        <div className="row d-flex justify-content-center">
          {loading ? (
            <div className="col-12 text-center mt-4">
              <h5>Loading...</h5>
            </div>
          ) : logobrandarray.length === 0 ? (
            <div className="col-12 text-center mt-4">
              <h5>No Data Found For Brands.</h5>
            </div>
          ) : (
            logobrandarray.map((item) => (
              <div
                className="col-sm-4 col-4 col-md-3 col-lg-2 mt-3 d-grid justify-content-center"
                key={item.id}
              >
                <div className="catrogieimgbrand d-flex justify-content-center align-items-center">
                  <img src={item.image} className="brand-img-size" alt="" />
                </div>
              </div>
            ))
          )}
        </div>
        <div>
          <Link to={`/brands`} className="underline-remove arrow-color">
            <Button className="float-end me-5 my-4 Brandsbtn font-17 font-20 font-weight-medium font-family px-4 py-2">
              <span className="font-family font-17 font-20 font-weight-medium">
                More
              </span>
            </Button>
          </Link>
        </div>
      </Row>
    </Container>
  );
};

export default Brandsround;
