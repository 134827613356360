import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import Bannerofproductspage from "./Bannerofproductspage";
import Header from "./Header";
import ModalImage from "react-modal-image";

const LocationShop = () => {
  window.scrollTo(0, 0);
  const listStyle = {
    listStyleType: "square", // Change the bullet style to square
  };

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [shopDetails, setShopDetails] = useState([]);
  const [cityID, setCityID] = useState(""); // Initialize cityID state
  const [cityName, setCityName] = useState("");
  const [loading, setLoading] = useState(true);


  // Define the API URLs for fetching data
  const quickLinksUrl = `/get_location/${id}`;
  const shopDetailsUrl = `/get_location_details/${id}`;

  useEffect(() => {
    // Fetch quick links data using Axios with a delay
    setTimeout(() => {
      axios
        .get(quickLinksUrl)
        .then((response) => {
          setData(response.data.data);
          setLoading(false);
          console.log(response.data.data, "shopp");

          // Set cityID to the id of the first item in the data array
          if (response.data.data.length > 0) {
            setCityID(response.data.data[0].id);
          }
        })
        .catch((error) => {
          console.error("Error fetching quick links data:", error);
          setLoading(false);
        });


    }, 1000); // Delay in milliseconds (adjust as needed)

    // Fetch company details data using Axios
    axios
      .get(shopDetailsUrl)
      .then((response) => {
        setShopDetails(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching company details data:", error);
      });

    axios.get("/get_city").then((res) => {
      const city_name = res.data.data.filter((city) => city.id == id);
      setCityName(city_name[0].city_name);
    });
  }, [id]);

  // const listStyle = {
  //   listStyleType: 'square', // Change the bullet style to square
  // };

  // const { id } = useParams();
  // const [data, setData] = useState([]);
  // const [shopDetails, setShopDetails] = useState([]);
  // const [cityID, setCityID] = useState(""); // Initialize cityID state
  // const [cityName, setCityName] = useState("");
  // // Define the API URLs for fetching data
  // const quickLinksUrl = `/get_location/${id}`;
  // const shopDetailsUrl = `/get_location_details/${id}`;

  // useEffect(() => {
  //   // Fetch quick links data using Axios
  //   axios
  //     .get(quickLinksUrl)
  //     .then((response) => {
  //       setData(response.data.data);
  //       console.log(response.data.data, "shopp");

  //       // Set cityID to the id of the first item in the data array
  //       if (response.data.data.length > 0) {
  //         setCityID(response.data.data[0].id);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching quick links data:", error);
  //     });

  //   // Fetch company details data using Axios
  //   axios
  //     .get(shopDetailsUrl)
  //     .then((response) => {
  //       setShopDetails(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching company details data:", error);
  //     });

  //   axios.get("/get_city").then((res) => {
  //     const city_name = res.data.data.filter((city) => city.id == id);
  //     setCityName(city_name[0].city_name);
  //   });
  // }, [id]);

  return (
    <>

      <Bannerofproductspage />
      <Header />
      <div className="d-lg-flex d-md-grid">
        <div className="lcationinfo col-lg-4 col-md-12 d-grid justify-content-center pt-lg-5 align-content-start addshopscroll scrollbar" id="style-7" >
          <div className="d-md-flex d-lg-grid force-overflow">
            <>
              <div>
                <p className="text-white font-27 font-32 font-weight-semibold font-family">
                  LOCATION
                </p>
                <p className="sndtext font-22 font-weight-semibold font-family">
                  Shops in {cityName}
                </p>
              </div>
              <div className="alladress font-16 font-weight-light  font-family active-name-color">
                <ul>
                  {/* {data.map((item) => ( */}

                  {loading ? (
                    <div className="col-12 text-center mt-4">
                      <h5>Loading...</h5>
                    </div>
                  ) : data.length > 0 ? (

                    data.map((item) => (
                      <Link
                        className={`underline-remove arrow-color ${item.id === cityID ? "active-link" : ""
                          }`} // Apply a CSS class to the active link
                        key={item.id}
                        onClick={() => setCityID(item.id)}
                      >
                        <li
                          style={listStyle}
                          className="cursor-pointer shopname font-13 font-14 font-weight-medium font-family"
                        >
                          {item.shop_name}
                        </li>
                      </Link>
                    ))
                  ) : (
                    <div className="col-12 text-center mt-4">
                      <h5>No Data Found for Shop.</h5>
                    </div>
                  )}
                </ul>
              </div>
            </>
          </div>
        </div>
        <div className="col-lg-8 col-md-12 map">
          <div>{/* ... */}</div>
          <div>
            {data
              .filter((obj) => obj.id === cityID) // Use strict equality comparison
              .map((item) => (
                <>
                  <div className="col-lg-4 adresslaoction" key={item.id}>
                    <div className="cntlcn  fw-bolder ps-4 py-1 font-26 font-weight-medium font-family">
                      CONTACT
                    </div>
                    <div className="dec" key={item.id}>
                      {" "}
                      {/* Add a unique key */}
                      <p className="px-3  mb-0">
                        <span className="font-15  font-weight-semibold font-family">
                          {item.shop_name} <br />
                        </span>
                        <hr className="mt-1"></hr>
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <span className="font-15 py-2 font-weight-medium font-family">
                              {item.mobile_no1} <br />
                              {item.mobile_no2}
                            </span>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                            <span className="font-15 py-2 font-weight-medium font-family ">
                              <div className="d-lg-block d-none d-md-none d-sm-none">
                                {" "}
                                {/* <img
                                  src={item.image}
                                  className="zoom img-fluid"
                                  
                                /> */}
                                {/* <ImageMagnifier
                                  src={item.image}
                                  // height="100%"
                                  magnifierWidth={300}
                                  magnifierHeight={200}
                                  zoomLevel={3}
                                /> */}

                                <ModalImage
                                  small={item.image}
                                  medium={item.image}
                                />
                              </div>
                              <img
                                src={item.image}
                                className="brand-img-size shadow img-fluid d-lg-none d-md-none d-sm-block"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>
                        <hr className=" mt-1"></hr>

                        <div className=" font-15   py-2 font-weight-light font-family">
                          <span className="font-15 font-weight-semibold font-familys ">
                            Address :
                          </span>{" "}
                          {item.address}
                        </div>
                      </p>
                    </div>
                  </div>
                  <iframe
                    src={item.link}
                    width="100%"
                    height="600"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationShop;
