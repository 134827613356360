import React, { useState, useEffect } from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import location from "../assets/images/locations__2_-removebg-preview.png";

const LocationRounds = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/get_city");
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

  }, []);

  return (
    <Container fluid>
      <div className="location-main mb-1">
        <Row className="locationcontainer mt-lg-5 d-flex justify-content-center">
          <div className="font-32 font-weight-medium text-center font-family title-location">
            LOCATION
          </div>
          <div className="row d-flex justify-content-center">
            {loading ? (
              <div className="col-12 text-center mt-4">
                <h5>Loading...</h5>
              </div>
            ) : data.length === 0 ? (
              <div className="col-12 text-center mt-4">
                <h5>No Data Found For Location.</h5>
              </div>
            ) : (
              data.map((item) => (
                <div
                  key={item.id}
                  className="roundf col-sm-4 col-4 col-md-3 col-lg-2 d-grid justify-content-center"
                >
                  <div className="catrogieimglocation my-3 d-flex justify-content-center align-items-center">
                    <Link
                      to={`/locationshop/${item.id}`}
                      className="underline-remove arrow-color d-flex justify-content-center"
                    >
                      <img
                        src={location}
                        className="w-75 h-75 cursor-pointer"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="fs-3 text-center  cursor-pointer">
                    <p className="font-weight-medium font-family font-75 font-15 font-30">{item.city_name}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default LocationRounds;
