import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

function Bannerofproductspage() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState([]);

  useEffect(() => {
    const quickLinksUrl = `/get_banner/${id}`;
    const logurl = `/get_logo`;

    axios
      .get(quickLinksUrl)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching quick links data:", error);
      });

    axios
      .get(logurl)
      .then((response) => {
        setCategoryDetails(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching company details data:", error);
      });
  }, [id]);

  return (
    <Container fluid>
      <Row className="banner topPadding">
        {data.map((item) => (
          <>
            <Col
              lg={3}
              key={item.id}
              className="productbannerleft"
              style={{
                backgroundColor: item.background_color_left,
              }}
            >
              {/* Left Section Content */}
            </Col>

            <Col
              lg={9}
              className="productbannerright"
              style={{
                backgroundColor: item.background_color_right,
              }}
            >
              <Col>
                {categoryDetails.map((item) => (
                  <Link to={`/`}>
                    <img src={item.image} fluid alt="" className="logo" />
                  </Link>
                ))}
              </Col>
              <Col>
                {data.map((item) => (
                  <Col>
                    <img
                      src={item.image}
                      alt=""
                      className="bannerimg shadow-lg img-fluid"
                    />
                  </Col>
                ))}
                 <Col>
              <div className="bannerconatctform text-white  contact-top-50 end-0 translate-middle-contact d-lg-block d-none">
                <ContactForm/>
              </div>
            </Col>
              </Col>
            </Col>
          </>
        ))}
      </Row>
    </Container>
  );
}

export default Bannerofproductspage;
