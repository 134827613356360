import React, { createContext, useState } from "react";

const MySecondContext = createContext();

export default function CategoryBanner({ children }) {
  const [bannerCategoryID, setBannerCategoryID] = useState("");

  return (
    <MySecondContext.Provider value={{ bannerCategoryID, setBannerCategoryID }}>
      {children}
    </MySecondContext.Provider>
  );
}

export { MySecondContext };
