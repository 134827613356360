import React from "react";
import Header from "../Component/Header";
import Bannerofproductspage from "../Component/Bannerofproductspage";
import ContactInformation from "../Component/ContactInformation";

const Contact = () => {
  return (
    <div>
       <Bannerofproductspage />
       <Header/>
      <ContactInformation />
      {/* <LocationShop /> */}
    </div>
  );
};

export default Contact;
