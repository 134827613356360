import React from 'react'
import Bannerofproductspage from '../../Component/Bannerofproductspage'
import Sale from '../../Pages/Home/Sale'
import Header from "../../Component/Header";
import Moreaboutus from './Moreaboutus';

const Aboutus = () => {
  return (
    <>
 <div>
 
      <Bannerofproductspage />
      <Header/>
      </div>
      {/* <Sale/> */}
      <Moreaboutus/>
          </>
  )
}

export default Aboutus
