import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";


const Brandlists = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100); // Adjust this value to set items per page

  useEffect(() => {
    const apiUrl = "/get_brands";
    axios
      .get(apiUrl)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page changes
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Container fluid className="justify-content-center d-grid">
      <div>
      <Nav className="d-flex justify-content-center py-4 flex-grow-1 m-1 headerLink">
          {currentItems.map((item) => {
            return (
              <div className="text-center brand-card-padding col-sm-3  col-4 col-lg-1  col-md-2 my-0" key={item.id}>
                <p className="">
                <img
                      src={item.image}
                      className="img-fluid"
                      alt=""
                    />
                </p>
                </div>
            //   <p className="mx-3 px-4 py-2 shadow vert-move my-3 brandNamecolor" key={item.id}>
            //     {/* {item.title} */}
            //     <img
            //           src={item.image}
            //           className="brand-img-size  m-4 img-fluid fixed-image "
            //           alt=""
            //         />
            //   </p>
            );
          })}
          <br />
        </Nav>
      </div>

        {/* Pagination */}
       <div>
       <ul className="pagination d-flex justify-content-center">
          {Array(Math.ceil(data.length / itemsPerPage))
            .fill()
            .map((_, index) => (
              <li
                key={index}
                className={`page-item ${
                  index + 1 === currentPage ? "active" : ""
                }`}
              >
                <a
                  onClick={() => paginate(index + 1)}
                  className="page-link"
                  href="#"
                >
                  {index + 1}
                </a>
              </li>
            ))}
        </ul>
       </div>
      </Container>
    </>
  );
};

export default Brandlists;
