import React from 'react'
import Bannerofproductspage from '../Component/Bannerofproductspage'
import Header from '../Component/Header'
import BrandPageList from '../Component/BrandPageList'
import Brandlists from '../Component/Brandlists'
import Counter from '../Component/Counter'
import Multibrand from '../Component/Multibrand'

function Brands() {
  window.scrollTo(0, 0);
  return (
    <div>
       
    <Bannerofproductspage />
    <Header/>
      <BrandPageList/>
      {/* <Brandsround/> */}
      <Counter/>
      <Brandlists/>
      <Multibrand/>   
            </div>
      
  )
}

export default Brands
