import React from "react";
import Bestseller from "./Bestseller";
import Popularcategories from "./Popularcategories";
import Sale from "./Sale";
import Header from "../../Component/Header";
import Brandsround from "../../Component/Brandsround";
import TrendyProducts from "../../Component/TrendyProducts";
import LocationRounds from "../../Component/LocationRounds";
import Bannerofproductspage from "../../Component/Bannerofproductspage";
// import Bannerofproductspage from "../../Component/Bannerofproductspage";
import { useParams } from "react-router-dom";
// import Demo from '../../Component/Demo'
// import Demo2 from '../../Component/Demo2'

const MainLanding = () => {
  window.scrollTo(0, 0);
  const { id } = useParams(); // <-- access id match param here
  return (
    <div>
     
      <Bannerofproductspage />
      <Header />
      <div>
        <Sale />
      </div>
      <div>
        {" "}
        <Popularcategories />
      </div>
      <div><Bestseller/></div>
      {/* <Demo />
      <Demo2 /> */}
     <Brandsround/> 
     <TrendyProducts />
    <LocationRounds/>
    </div>
  );
};

export default MainLanding;
