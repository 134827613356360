import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row} from "react-bootstrap";
import Slider from "react-slick";
function Bestseller() {
  const [bestsel, setData] = useState([]); // Initialize data as an empty array

  let bestslides

  bestsel.length < 4 ? bestslides = bestsel.length : bestslides = 4

  const settings = {
    
    infinite: false,
    speed: 5000,
    slidesToShow: bestslides,
    slidesToScroll: bestslides,
    initialSlide: bestslides,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = "/get_topseller_products"; // Replace with your API URL

    try {
      // Make the API request using Axios
      axios.get(apiUrl).then((response) => {
        setData(response.data.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  return (
    <>
      <div>
        <Container fluid className="mb-1 mt-5">
          <div>
            <div className="font-32 font-weight-medium font-family ps-lg-5">
              Best Seller
            </div>
          </div>{" "}
          <Row className=" d-flex  bestseller-main">
            <Slider {...settings}>
              {bestsel.map((item, index) => {
                return (
                  <>
                    <div className="sellerouter" key={index}>
                      <div className="seller border-bottom-0">
                        <img
                          src={item.thumbnail_image}
                          alt=""
                          className=" img-fluid bestsellimg"
                        />
                      </div>
                    </div>
                    <div className=" text-center">
                      <h4 className="pt-2 font-13  font-26 font-weight-semibold font-family bestsellertext ">
                        {item.name}
                      </h4>
                    </div>
                  </>
                );
              })}
            </Slider>{" "}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Bestseller;
