import React, { useState, useEffect } from "react";
import axios from "axios";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import ImageMagnifier from "./ImageMagnifier";
import Col from "react-bootstrap/Col";
import Slider from "react-slick";


const TrendyProducts = () => {
  const [trendyproduct, setTrendyproduct] = useState([]);

  let slides

  trendyproduct.length < 5 ? slides = trendyproduct.length : slides = 5

  const settings = {
    infinite: false,
    speed: 5000,
    slidesToShow: slides,
    slidesToScroll: slides,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }; // Initialize data as an empty array

  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = "/get_trending_products"; // Replace with your API URL

    try {
      // Make the API request using Axios
      axios.get(apiUrl).then((response) => {
        setTrendyproduct(response.data.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  return (
    <>
      
      <Container fluid className="trendycontainer">
        <div className="font-27 font-32 font-weight-medium font-family ps-lg-5">
          Trendy Products
        </div>
        <Row className=" pt-5">
              <Slider {...settings}>
                {trendyproduct.map((item) => (
                  <>
                    <div className="rested" key={item.id}>
                      {" "}
                      <div className="col-sm-6 col-6 col-lg-3 col-md-6">
                        <div className="profile-card ">
                          <div className="profile-img">
                            <img
                              src={item.thumbnail_image}
                              alt="Team Image"
                              className="img-fluid "
                            />
                          </div>
                          <div className="profile-content">
                            <h2 className="title font-22 font-weight-bold font-family">
                              {item.name}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
        </Row>
      </Container>
    </>
  );
};

export default TrendyProducts;