import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/MainLanding";
import Footer from "./Component/Footer";
import Brands from "./Pages/Brands";

import Productdisplaypage from "./Component/Productdisplaypage";

import About from "./Pages/About/Aboutus";
import Header from "./Component/Header";


import Contact from "./Pages/Contact";
import LocationShop from "./Component/LocationShop"
import Productcategoriesrounds from './Component/Productcategoriesrounds'
import Bannerofproductspage from './Component/Bannerofproductspage'
import BannerCategory from './Component/BannerCategory'
import ProductDitailPage from './Component/ProductDitailPage'
import { useLocation } from "react-router-dom";
import BrandPageList from "./Component/BrandPageList";
import BrandParticularDetails from "./Component/BrandParticularDetails";
import CommingSoon from './Pages/CommingSoon'

// import Contact from './Contact';

function Main() {

  const location = useLocation()

  const path = location.pathname
  // window.scrollTo(0, 0);
  return (
    <>
      {/* The Routes component defines your routing structure */}

{/* {path === '/' ? <Bannerofproductspage /> : <BannerCategory/>} */}

{/* <Header /> */}
{/* <Bannerofproductspage /> */}
      <Routes>
        {/* Each Route defines a specific route and the component to render */}
        {/* <Route path="/" element={<CommingSoon />} /> */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/header/:id" element={<Header/>}></Route> */}
        <Route path="/about" element={<About/>} />
        <Route path="/locationshop/:id" element={<LocationShop/>}></Route>
        <Route path="/locationshopdetails/:id" element={<LocationShop/>}></Route>
        <Route path="/categorydetails/:id" element={<Productcategoriesrounds/>}></Route>
        <Route path="/productlist/:id" element={<Productdisplaypage/>}></Route>
        <Route path="/productdetails/:id" element={<ProductDitailPage/>}></Route>
        <Route path="/brandparticulardetails/:id" element={<BrandParticularDetails/>}></Route>
        <Route path="/brands" element={<Brands/>} />
        <Route path="/brands" element={<BrandPageList/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
        <Footer />
    </>
  );
}

export default Main;
