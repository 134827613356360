import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import BannerCategory from "./BannerCategory";
import Header from "./Header";
import { MyContext } from "../context/ProductsContext";
import { MySecondContext } from "../context/CategoryBanner";
import ContactForm from "./ContactForm";

const Productdisplaypage = () => {
   const { setProductCategoryID } = useContext(MyContext);
  const { id } = useParams();
  const [details, setData] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
   // Initialize contactDetails as an empty array

  const [category, setCategory] = useState("");
  const [error, setError] = useState(null);
  const { bannerCategoryID } = useContext(MySecondContext);
  console.log(bannerCategoryID, "bannerCategoryIDbannerCategoryID");

  const asd = localStorage.setItem("product_Id", id);

  useEffect(() => {
    // Define the API URL you want to fetch data from
    const apiUrl = `/get_products/${id}`; // Replace with your API URL
    const apibannerUrl = `/get_banner/${localStorage.getItem('category_Id')}`; // Replace with your API URL
    const logurl = `/get_logo`;

    const fetchData = async () => {
      try {
        // Make the API request using Axios
        const response = await axios.get(apiUrl);
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 429) {
          // If a 429 error is encountered, you can implement a retry mechanism
          // Here's an example of using exponential backoff for retries
          const retryDelay = Math.pow(2, error.config.retryCount || 0) * 1000; // Exponential backoff
          console.log(`Rate limited. Retrying in ${retryDelay}ms...`);
          setTimeout(() => {
            // Retry the API request
            axios.request(error.config);
          }, retryDelay);
        }
      }

      try {
        // Make the API request using Axios
        const response = await axios.get(apibannerUrl);
        setBannerImage(response.data.data);
        console.log(response.data.data, "newbanner");
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        // Make the first API request to get the main category title
        const CategoryResponse = await axios.get(`/get_maincategory_id/${id}`);
        setCategory(CategoryResponse.data.data);
        console.log(CategoryResponse.data.data, "poojaaaaaaaaa");
        } catch (error) {
        setError("Error fetching data: " + error.message);
      }

      // Fetch company details data using Axios
      axios
        .get(logurl)
        .then((response) => {
          setLogoImage(response.data.data); // Corrected line
        })
        .catch((error) => {
          console.error("Error fetching company details data:", error);
        });
    };
    setTimeout(() => {
      fetchData();
    });
  }, [id, bannerCategoryID]); // Make sure to include id as a dependency to trigger the effect when it changes
  return (
    <>
      {/* <BannerCategory /> */}
      <Container fluid>
        <Row className=" banner topPadding">
          <Col lg={3} className="productbannerleft "></Col>
          <Col lg={9} className="productbannerright">
            <Col>
              {" "}
              {logoImage.map((item) => (
                <Link to={`/`}>
                  <img src={item.image} fluid alt="" className="logo" />
                </Link>
              ))}
            </Col>
            <Col>
              {" "}
              {Array.isArray(bannerImage) &&
                bannerImage.map((item) => (
                  <Col key={item.id}>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <img
                       src={item.image}
                        alt=""
                        className="bannerimg shadow-lg img-fluid"
                      />
                    </div>
                  </Col>
                ))}
              <Col>
                <div className="bannerconatctform text-white  contact-top-50 end-0 translate-middle-contact d-lg-block d-none">
                  <ContactForm />
                </div>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
      {/* ================================== */}
      <Header />
      <Container fluid className=" d-grid ">
        <div className="font-27 font-32 font-weight-medium font-family pt-3 d-flex justify-content-center">
          PRODUCTS
          {/* {categoryName} */}
        </div>
        <div className=" row">
          {details.length !== 0 ? (
            details.map((item) => {
              return (
                <>
                  <div
                    className=" col-lg-3 col-md-4 col-sm-4 col-6 pt-lg-4"
                    key={item.id}
                  >
                    <Link
                      to={`/productdetails/${item.id}`}
                      className=""
                      onClick={() => setProductCategoryID(id)}
                    >
                      <div className="productcard d-grid justify-content-center shadow-lg mb-5 bg-body rounded">
                        <div className=" productimg">
                          <img
                            src={item.thumbnail_image}
                            className=" img-fluid "
                            alt=""
                          />
                        </div>
                        <div className="text-center py-2">
                          <div className="font-22 font-17 font-weight-bold font-family text-uppercase">
                            {" "}
                            {item.name}
                          </div>
                          <p className="font-20 font-17 font-weight-light font-family">
                            {item.brands}
                          </p>
                        </div>
                      </div>
                  
                  </Link>
                </div>
                </>
              );
            })
          ) : (
            <div className="col-12 text-center mt-4">
              <h5>Loading....</h5>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Productdisplaypage;
